<!--
* @description:
* @fileName pageEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog
    ref="drawerRef"
    v-model="dialogFormVisible"
    :before-close="handleClose"
    :title="title"
  >
    <el-form ref="formRef" :inline="true" :model="data" :rules="rules">
      <el-form-item v-if="form.status == 'ACCEPT'" label="状态" prop="status">
        <el-select v-model="data.status">
          <el-option label="更改为翡翠用户" value="EMERALD" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.status == 'REFUSE'" label="状态" prop="status">
        <el-select v-model="data.status">
          <el-option label="更改为钻石用户" value="DIAMOND" />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="form.status == 'PENDING_REVIEW'"
        label="状态"
        prop="status"
      >
        <el-select v-model="data.status">
          <el-option label="审核通过，客户升级为钻石用户" value="DIAMOND" />
          <el-option label="审核不通过" value="EMERALD" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div>
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="save()">提交</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
  import { applyStatus, editStatus } from '@/api/passApply'

  export default defineComponent({
    name: 'PageEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        pageList: [],
        eventTypeList: [],
        form: {
          status: '',
        },
        data: {},
        rules: {
          status: [
            { required: true, trigger: 'blur', message: '请选择审核状态' },
          ],
          listName: [
            { required: true, trigger: 'blur', message: '请输入列表名称' },
          ],
          listAddress: [
            { required: true, trigger: 'blur', message: '请输入列表地址' },
          ],
          listTime: [
            { required: true, trigger: 'blur', message: '请输入列表时间' },
          ],
          listCover: [
            { required: true, trigger: 'change', message: '请选择事件图片' },
          ],
          pageId: [
            { required: true, trigger: 'change', message: '请选择事件模版' },
          ],
        },
        title: '状态管理',
        dialogFormVisible: false,
        dialogVisible: false,
        dialogImageUrl: '',
        list: [],
      })

      const showEdit = async (row) => {
        state.form = Object.assign({}, row)
        state.dialogFormVisible = true
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }

      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            if (state.form.status == 'PENDING_REVIEW') {
              await applyStatus({
                level: state.data.status,
                status: state.data.status == 'DIAMOND' ? 'ACCEPT' : 'REFUSE',
                uid: state.form.uid,
              })
            }
            if (
              state.form.status == 'ACCEPT' ||
              state.form.status == 'REFUSE'
            ) {
              await editStatus({
                level: state.data.status,
                uid: state.form.uid,
              })
            }
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }
      return {
        ...toRefs(state),
        showEdit,
        close,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }

  .demo-drawer__content {
    height: 85vh;
    overflow: auto;
  }
  .demo-drawer__footer {
    position: fixed;
    right: 20px;
    bottom: 10px;
  }
</style>
